<template>
	<div
		class="coupon-play d-flex p-3"
		:class="{ 'coupon-play--disabled': disabled }"
	>
		<div class="d-flex align-item-center mr-3">
			<IconSax
				name="ticket-discount"
				type="outline"
				:color="disabled ? 'var(--text-color)' : 'var(--maincolor)'"
			/>
		</div>

		<div class="coupon-play__container d-flex flex-column w-100">
			<div class="d-flex align-items-center justify-content-between mb-2">
				<div class="code-containter d-flex">
					<TextPlay
						class="mr-2"
						size="xxs"
						color="var(--text-color)"
						:text="$t('gamification.coupon_code')"
					/>
					<TextPlay
						:color="disabled ? 'var(--text-color)' : 'var(--fontcolor)'"
						weight="semibold"
						size="sm"
						:text="coupon.code"
					/>
				</div>
			</div>

			<div class="d-flex">
				<div class="d-flex flex-column mr-4">
					<TextPlay
						class="mr-2"
						size="xxs"
						color="var(--text-color)"
						:text="coupon.name"
					/>
					<TextPlay
						size="xs"
						color="var(--text-color)"
						weight="semibold"
						:text="coupon.quantity"
					/>
				</div>
				<div class="d-flex flex-column">
					<TextPlay
						class="mr-2"
						size="xxs"
						color="var(--text-color)"
						:text="$t('gamification.discount_value')"
					/>
					<TextPlay
						size="xs"
						color="var(--text-color)"
						weight="semibold"
						:text="'R$' + coupon.money"
					/>
				</div>
			</div>
		</div>

		<div class="coupon-play__container__actions d-flex flex-column justify-content-between">
			<TextPlay
				v-show="!disabled"
				color="var(--maincolor)"
				size="xs"
				:text="$t('gamification.copy')"
				@click="copyCouponCode"
			/>
			<TextPlay
				v-if="!disabled && enableExclude"
				color="var(--text-color)"
				size="xs"
				:text="$t('gamification.exclude')"
				@click="exclude"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		coupon: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		enableExclude: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		copyCouponCode() {
			navigator.clipboard.writeText(this.coupon.code);
		},
		exclude() {
			this.$emit("exclude", this.coupon);
		},
	},
};
</script>

<style lang="scss" scoped>
.coupon-play {
	background-color: var(--caption-bg-color);
	border-radius: 8px;

	&--disabled {
		opacity: 0.5;
	}

	&__container {
		.code-containter {
			flex-direction: row;
			align-items: center;
		}

		&__actions {
			.text-play {
				cursor: pointer;
			}
		}
	}
}

@media (max-width: 768px) {
	.coupon-play {
		&__container {
			.code-containter {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
}
</style>
