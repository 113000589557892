<template>
	<div class="combo-table-item">
		<div class="combo-table-item__row">
			<div
				@click="visible = !visible"
				class="combo-table-item__row__cel"
			>
				<IconSax
					:name="visible ? 'minus' : 'add'"
					primary
				/>
				<TextPlay
					color="var(--fontcolor)"
					size="sm"
					:text="combo?.title"
				/>
			</div>
			<div class="combo-table-item__row__cel">
				<TextPlay
					color="var(--fontcolor)"
					size="sm"
					:text="convertIsoDate(combo?.created_at)"
				/>
			</div>
			<div class="combo-table-item__row__cel">
				<TextPlay
					color="var(--fontcolor)"
					size="sm"
					:text="convertIsoDate(combo?.updated_at)"
				/>
			</div>
			<div class="combo-table-item__row__cel">
				<b-progress
					:value="combo?.percentage_complete"
					:max="100"
					show-value
					class="w-75"
				></b-progress>
			</div>
			<div
				@click="handleActionsCombo"
				class="combo-table-item__row__cel"
			>
				<TextPlay
					:color="isDeactivated ? 'var(--maincolor)' : 'var(--feedback-error-500)'"
					weight="semibold"
					size="sm"
					:text="isDeactivated ? $t('combos.activate') : $t('combos.deactivate')"
				/>
			</div>
		</div>

		<b-collapse
			accordion="combo-table-details"
			v-model="visible"
		>
			<ComboTableItemDetails
				@deactivate-course="$emit('deactivate-course', $event)"
				:courses="combo?.courses"
			/>
		</b-collapse>
	</div>
</template>

<script>
import TextPlay from "@/components/common/TextPlay.vue";
import { mapActions } from "vuex";
import { convertIsoDate } from "@/utils";
import ComboTableItemDetails from "./ComboTableItemDetails.vue";

export default {
	components: {
		TextPlay,
		ComboTableItemDetails,
	},
	props: {
		combo: {
			type: Object,
			required: true,
		},
		isDeactivated: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	methods: {
		...mapActions({
			deactivateCombo: "combos/deactivateCombo",
			reactivateCombo: "combos/reactivateCombo",
		}),
		convertIsoDate,
		async handleActionsCombo() {
			if (this.isDeactivated) {
				await this.handleReactivateCombo(this.combo.pivot.id);
			} else {
				await this.handleDeactivateCombo(this.combo.pivot.id);
			}
		},
		async handleDeactivateCombo() {
			await this.deactivateCombo(this.combo.pivot.id);
			this.$emit("deactivate-combo", this.combo.pivot.id);
		},
		async handleReactivateCombo() {
			await this.reactivateCombo(this.combo.pivot.id);
			this.$emit("reactivate-combo", this.combo.pivot.id);
		},
	},
};
</script>

<style lang="scss" scoped>
.combo-table-item {
	border-bottom: 1px solid var(--divisor);

	&__row {
		display: flex;
		align-items: center;
		padding: 17px 0;

		&__cel {
			display: flex;
			align-items: center;
			gap: 8px;

			&:nth-child(1) {
				cursor: pointer;
				width: 31.4%;

				.text-play {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 90%;
				}
			}
			&:nth-child(2) {
				width: 19.6%;
			}
			&:nth-child(3) {
				width: 19.6%;
			}
			&:nth-child(4) {
				width: 24.4%;
			}
			&:nth-child(5) {
				cursor: pointer;
				width: 5%;
			}
		}
	}
}
</style>
