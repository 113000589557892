<template>
	<div class="details">
		<div class="details__header">
			<div class="details__header__col-title">
				<TextPlay
					color="var(--subtitle-color)"
					weight="semibold"
					size="sm"
					:text="$t('combos.courses')"
				/>
			</div>
			<div class="details__header__col-title">
				<!-- <TextPlay
							color="var(--subtitle-color)"
							weight="semibold"
							size="sm"
							:text="$t('combos.classes')"
						/> -->
			</div>
			<div class="details__header__col-title">
				<!-- <TextPlay
							color="var(--subtitle-color)"
							weight="semibold"
							size="sm"
							:text="$t('combos.entries')"
						/> -->
			</div>
			<div class="details__header__col-title">
				<!-- <TextPlay
							color="var(--subtitle-color)"
							weight="semibold"
							size="sm"
							:text="$t('combos.validity')"
						/> -->
			</div>
			<div class="details__header__col-title">
				<TextPlay
					color="var(--subtitle-color)"
					weight="semibold"
					size="sm"
					:text="$t('combos.courses_progress')"
				/>
			</div>
			<div class="details__header__col-title">
				<!-- <TextPlay
					color="var(--subtitle-color)"
					weight="semibold"
					size="sm"
					:text="$t('combos.actions')"
				/> -->
			</div>
		</div>

		<div class="details__body">
			<div
				v-for="(course, index) in courses"
				:key="index"
				class="details__body__row"
			>
				<div class="details__body__row__cel">
					<TextPlay
						color="var(--fontcolor)"
						size="sm"
						:text="course?.title"
					/>
				</div>
				<div class="details__body__row__cel">
					<!-- <TextPlay
								color="var(--fontcolor)"
								size="sm"
								text="Turma 1"
							/> -->
				</div>
				<div class="details__body__row__cel">
					<!-- <TextPlay
								color="var(--fontcolor)"
								size="sm"
								:text="convertIsoDate(course?.created_at)"
							/> -->
				</div>
				<div class="details__body__row__cel">
					<!-- <TextPlay
								color="var(--fontcolor)"
								size="sm"
								:text="convertIsoDate(course?.updated_at)"
							/> -->
				</div>
				<div class="details__body__row__cel">
					<b-progress
						:value="course?.percentage_complete"
						:max="100"
						show-value
						class="w-75"
					></b-progress>
				</div>
				<div class="details__body__row__cel">
					<!-- <TextPlay
						color="var(--feedback-error-500)"
						weight="semibold"
						size="sm"
						:text="$t('combos.deactivate')"
					/> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextPlay from "@/components/common/TextPlay.vue";
import { mapActions } from "vuex";

export default {
	components: {
		TextPlay,
	},
	props: {
		courses: {
			type: Array,
			required: true,
		},
	},
	methods: {
		...mapActions({
			deactiveCourse: "myCourses/deactiveCourse",
		}),
		async handleDeactivateCourse(course) {
			await this.deactiveCourse(course.pivot.id);
			this.$emit("deactivate-course", course.pivot.id);
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	padding: 8px 0 16px 30px;

	&__header {
		display: flex;

		&__col-title {
			&:nth-child(1) {
				width: 20%;

				.text-play {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 90%;
				}
			}
			&:nth-child(2) {
				width: 10%;
			}
			&:nth-child(3) {
				width: 20%;
			}
			&:nth-child(4) {
				width: 20%;
			}
			&:nth-child(5) {
				width: 25%;
			}
			&:nth-child(6) {
				width: 5%;
			}
		}
	}

	&__body {
		&__row {
			display: flex;
			align-items: center;
			padding: 16px 0;

			&__cel {
				display: flex;
				align-items: center;

				&:nth-child(1) {
					width: 20%;

					.text-play {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 90%;
					}
				}
				&:nth-child(2) {
					width: 10%;
				}
				&:nth-child(3) {
					width: 20%;
				}
				&:nth-child(4) {
					width: 20%;
				}
				&:nth-child(5) {
					width: 25%;
				}
				&:nth-child(6) {
					cursor: pointer;
					width: 5%;
				}
			}
		}
	}
}
</style>
