<template>
	<div class="medal-input d-flex flex-column p-0">
		<TextPlay
			class="medal-input__label mb-2"
			color="var(--fontcolor)"
			weight="bold"
			size="sm"
			:text="$t('gamification.select_a_medal')"
		/>
		<div
			v-b-toggle="id"
			class="medal-input__actions d-flex align-items-center p-0"
		>
			<Emblem
				:size="size"
				:src="medal?.emblem?.picture"
			/>
			<div
				v-if="medal.name"
				class="medal-input__actions__name d-flex align-items-center justify-content-center"
			>
				<TextPlay
					size="xs"
					weight="semibold"
					color="var(--gamification-badge-color)"
					:text="medal.name"
				/>
			</div>
			<IconSax
				name="arrow-down-1"
				size="1.2rem"
				class="medal-input__select-icon p-2"
				primary
			/>
		</div>
		<MedalsSidebar
			:id="id"
			@select="selectMedal"
		/>
	</div>
</template>

<script>
import MedalsSidebar from "@/components/MedalsSidebar.vue";
import Emblem from "@/components/Emblem.vue";

export default {
	components: {
		MedalsSidebar,
		Emblem,
	},
	data() {
		return {
			id: `medal-input_actions_medal-${this._uid}`,
		};
	},
	props: {
		medal: {
			type: Object,
		},
		size: {
			type: String,
			default: "base",
		},
	},
	methods: {
		selectMedal(emblem) {
			this.$emit("input", emblem);
		},
	},
};
</script>

<style lang="scss" scoped>
.isax {
	cursor: pointer;
}

.medal-input {
	&__actions {
		&__name {
			padding: 8px;
			text-align: start;
			background-color: var(--caption-bg-color);
			border-radius: 4px;
			margin-left: 8px;
			margin-top: 0;

			.text-play {
				text-align: start;
				max-width: 23ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>
