<template>
	<portal to="app">
		<b-sidebar
			:id="id"
			sidebar-class="medals-sidebar p-4"
			width="520px"
			backdrop
			right
			no-header
			no-footer
		>
			<div class="medals-sidebar__header d-flex justify-content-between mb-4">
				<TextPlay
					color="var(--fontcolor)"
					size="lg"
					weight="semibold"
					:text="$t('gamification.select_a_medal')"
				/>

				<IconSax
					v-b-toggle="id"
					name="close-circle"
				/>
			</div>

			<div class="medals-sidebar__body">
				<div
					v-if="medalsList.length > 1"
					class="medals-sidebar__configured-medals d-flex flex-column"
				>
					<Medal
						v-for="(medal, index) in medalsList"
						:key="index"
						:name="medal.name"
						:picture="medal.emblem.picture"
						horizontal
						class="mb-2"
						v-b-toggle="id"
						@click="$emit('select', medal)"
					/>
				</div>

				<div
					v-else
					class="medals-sidebar__configured-medals d-flex flex-column"
				>
					<TextPlay
						size="xs"
						color="var(--text-color)"
						:text="$t('gamification.no_medals_configurated_sidebar')"
					/>
					<ButtonPlay
						class="mt-3"
						w="236px"
						type="normal"
						icon="buy-crypto"
						:text="$t('gamification.to_config_medals')"
						@click="$router.push({ name: 'gamification-config', query: { tab: 1 } })"
					/>
				</div>
			</div>
		</b-sidebar>
	</portal>
</template>

<script>
import Medal from "@/components/Medal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		Medal,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			medalsList: "gamification/getMedals",
		}),
	},
	methods: {
		...mapActions("gamification", ["fetchMedals"]),
	},
	async mounted() {
		await this.fetchMedals();
	},
};
</script>

<style lang="scss">
.vue-portal-target {
	.b-sidebar-outer {
		z-index: 3016;
	}
}

.medals-sidebar {
	background-color: var(--backgroundcolor) !important;

	.medal-image {
		cursor: pointer;
	}
}
</style>
