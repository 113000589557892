<template>
	<div class="gamification-member px-2">
		<div class="gamification-member_info p-0">
			<div class="d-flex mb-3">
				<TextPlay
					class="mr-2"
					weight="bold"
					color="var(--fontcolor)"
					:text="$t('gamification.ranking_score')"
				/>
				<TextPlay
					color="var(--text-color)"
					:text="member?.points ?? 0"
				/>
			</div>

			<div class="d-flex mb-3">
				<TextPlay
					class="mr-2"
					weight="bold"
					color="var(--fontcolor)"
					:text="$t('gamification.ranking_position')"
				/>
				<TextPlay
					color="var(--text-color)"
					:text="`${member?.ranking ?? 0}º`"
				/>
			</div>
		</div>

		<div class="gamification-member_achievements p-0">
			<Achievements
				:title="$t('gamification.medals_won')"
				:medalsList="medalList"
				:limit="medalsQuantity"
			/>
		</div>

		<DividerPlay class="my-5" />

		<div class="gamification-member_add-point d-flex flex-column">
			<TextPlay
				class="mb-4"
				weight="bold"
				color="var(--fontcolor)"
				:text="$t('gamification.manual_points_addition')"
			/>

			<InputDescribed
				inputWidth="105px"
				:placeholder="$t('gamification.zero_points')"
				:title="$t('gamification.add_points')"
				:description="$t('gamification.add_points_description')"
				@input="pointsToAdd = $event"
				min="1"
				:value="pointsToAdd"
				:preventNonNumeric="true"
				:preventDots="true"
				:preventHyphens="true"
				:preventLetters="true"
				:preventSpaces="true"
			/>

			<ButtonPlay
				type="normal"
				w="105px"
				class="py-2 mt-3"
				:text="$t('gamification.add')"
				@click="savePointsManually()"
			/>

			<FeedbackModalPlay
				id="points-feedback"
				status="success"
				noCloseOnEsc
				noCloseOnBackdrop
				@close="pointsToAdd = ''"
				:title="$t('gamification.points_feedback_title')"
				:content="$t('gamification.points_feedback_content', { points: pointsToAdd, member: member?.member?.name })"
			/>
		</div>

		<DividerPlay class="my-5" />

		<div class="gamification-member_add-medal d-flex flex-column">
			<TextPlay
				class="mb-4"
				weight="bold"
				color="var(--fontcolor)"
				:text="$t('gamification.manual_medal_addition')"
			/>

			<MedalInput
				:medal="medal"
				@input="medal = $event"
				size="lg"
			/>

			<ButtonPlay
				type="normal"
				w="105px"
				class="py-2 mt-3"
				:text="$t('gamification.save')"
				@click="saveMedalManually()"
			/>

			<FeedbackModalPlay
				id="medal-feedback"
				@close="medal = {}"
				noCloseOnEsc
				noCloseOnBackdrop
				status="success"
				:title="$t('gamification.medal_feedback_title')"
				:content="$t('gamification.medal_feedback_content', { member: member?.member?.name })"
			/>
		</div>

		<DividerPlay
			v-if="false"
			class="my-5"
		/>

		<div
			v-if="false"
			class="p-0 col-12 col-md-4"
		>
			<TextPlay
				class="mb-4"
				weight="bold"
				color="var(--fontcolor)"
				:text="$t('gamification.generated_coupons')"
			/>
			<Coupons />
		</div>
	</div>
</template>

<script>
import InputDescribed from "@/components/InputDescribed.vue";
import Achievements from "@/components/Achievements.vue";
import MedalInput from "@/components/MedalInput.vue";
import Coupons from "@/components/Coupons.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		InputDescribed,
		Achievements,
		MedalInput,
		Coupons,
	},
	data() {
		return {
			fetchLoading: false,
			pointsToAdd: null,
			member: {},
			medal: {},
			medalList: [],
		};
	},
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			medalsQuantity: "gamification/getMedalsConfiguredQuantity",
		}),
	},
	methods: {
		...mapActions({
			fetchMember: "gamification/fetchMember",
			fetchMembersMedals: "gamification/fetchMembersMedals",
			setPointsManually: "gamification/setPointsManually",
			setMedalManually: "gamification/setMedalManually",
		}),
		async savePointsManually() {
			const data = {
				member_id: this.memberId,
				points: this.pointsToAdd,
			};

			await this.setPointsManually(data);
			await this.loadMember();
			await this.loadMedalsMember();
			this.$bvModal.show("points-feedback");
		},
		async saveMedalManually() {
			const data = {
				member_id: this.memberId,
				medal_id: this.medal.id,
			};

			await this.setMedalManually(data);
			await this.loadMedalsMember();
			this.$bvModal.show("medal-feedback");
		},
		async loadMember() {
			this.member = await this.fetchMember(this.memberId);
		},
		async loadMedalsMember() {
			const { acquired } = await this.fetchMembersMedals(this.memberId);
			this.medalList = acquired;
		},
	},
	async mounted() {
		await this.loadMember();
		await this.loadMedalsMember();
	},
};
</script>
