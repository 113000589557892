<template>
    <div>
      <CourseCertificateRow  :member_id="this.member_id" :certificates="certificates" @update:certificates="handleCertificatesUpdate" />
    </div>
  </template>
  
  <script>
  import CourseCertificateRow from './CourseCertificateRow';
  import { mapGetters } from "vuex";
  import CertificateExtensionService from "@/services/resources/CertificateExtensionService";
  const certificateExtensionService = CertificateExtensionService.build();
  
  export default {
    name: 'App',
    components: {
        CourseCertificateRow
    },
    props:{
      member_id: {
        required: true
      }
    },
    computed: {
      ...mapGetters({
        getCurrentSite: "user/currentSite"
      }),
    },
    data() {
      return {
        certificates: []
      };
    },
    mounted(){
      this.handleCertificatesUpdate();
    },
    methods:{
      handleCertificatesUpdate(){
        let siteId = this.getCurrentSite.currentSite.id;

        certificateExtensionService
        .certificatesUpdate(siteId, this.member_id)
        .then((resp) => {
            this.certificates = resp;
        });
      }
    }
  }
  </script>