<template>
<Layout>
  <MembersComponent />
</Layout>
</template>

<script>
import Layout from '@/layout/Layout.vue'
import Slidebar from "@/components/Slidebar.vue";
import MembersComponent from "@/components/MembersComponent.vue";


export default {
  components: {
    Slidebar,
    Layout,
    MembersComponent
  },
};
</script>
<style  scoped>
.container-grid{
  display: grid;
  grid-template-columns: 100px 1fr;
}

.container-grid2{
  width: 100%;
}

.slidebarMobile {
    float: right;
    padding-top: 20px;
    position: relative;
    right: .6em;
}

.containerx {
  width: 98%;
}
</style>
