<template>
  <div class="certificate-extension">
      <h2 class="certificate-extension__title">{{ $t('members.texts.extensionCoursesTitle') }}</h2>
      <ul v-if="certificates.length>0" class="certificate-extension__list">
        <li class="certificate-extension__item" v-for="certificate in certificates" :key="certificate.id">
          <div class="certificate-extension__name">{{ certificate.course_name }}</div>

          <div v-if="certificate.auto_issuance">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.011 17C9.73087 17 9.46473 16.8884 9.26863 16.6931L5.30465 12.7447C4.89845 12.3401 4.89845 11.6704 5.30465 11.2658C5.71086 10.8612 6.38319 10.8612 6.78939 11.2658L10.011 14.4747L17.2106 7.30345C17.6168 6.89885 18.2891 6.89885 18.6953 7.30345C19.1016 7.70806 19.1016 8.37775 18.6953 8.78235L10.7534 16.6931C10.5573 16.8884 10.2911 17 10.011 17Z" fill="#81858e"/>
            </svg>
            <span class="certificate-extension__status">Certificado emitido</span>
          </div>

          <div class="certificate-extension__action"  v-else-if="!certificate.auto_issuance && certificate.status === 'pending' || certificate.status === 'not_requested'  ">
            <IconSax name="medal-star" primary type="outline" />
            <span class="certificate-extension__action-title" @click="certify(certificate.course_id)">{{ $t('members.texts.issueCertificateButton') }}</span>
          </div>
          <div v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.011 17C9.73087 17 9.46473 16.8884 9.26863 16.6931L5.30465 12.7447C4.89845 12.3401 4.89845 11.6704 5.30465 11.2658C5.71086 10.8612 6.38319 10.8612 6.78939 11.2658L10.011 14.4747L17.2106 7.30345C17.6168 6.89885 18.2891 6.89885 18.6953 7.30345C19.1016 7.70806 19.1016 8.37775 18.6953 8.78235L10.7534 16.6931C10.5573 16.8884 10.2911 17 10.011 17Z" fill="#81858e"/>
            </svg>
            <span class="certificate-extension__status">{{ $t('members.texts.certificateIssued') }}</span>
          </div>
        </li>
      </ul>
      <div v-else>
        <p class="extension__name">{{ $t('members.texts.noConfiguredExtensionCoursesMessage') }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import CertificateExtensionService from "@/services/resources/CertificateExtensionService";
  const certificateExtensionService = CertificateExtensionService.build();
  export default {
    name: 'CertificatesList',
    props: {
      certificates: {
        required: true
      },
      member_id: {
        required: true
      }
    },
    computed: {
      ...mapGetters({
        getCurrentSite: "user/currentSite"
      }),
    },
    data() {
      return {
        course_id: null
      };
    },
    mounted(){
      this.$root.$once("issuanceCertify", () => {
        certificateExtensionService
          .issuanceCertify(this.getCurrentSite.currentSite.id, this.member_id, this.course_id)
          .then((resp) => {
            this.$root.$off('issuanceCertify')
            this.$emit('update:certificates', resp);
          })
          .catch((err) => {
            console.log(err)
          });
      });
    },
    methods: {
      certify(course_id){
        this.course_id = course_id
        this.$root.$emit("bv::show::modal", "my-issuance-certificate",{course_id: course_id});
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>

  .extension__name{
    color: #999;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px 
  }

  .certificate-extension {
    padding-top: 2em;

    &__title {
      color: var(--fontcolor);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.6px;
    }

    &__list {
      padding-left: 0;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
    &__name {
      color: #81858e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__status {
      color: #81858e;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-left: 5px;
    }

    &__action {
      display: flex;
    }

    &__action-title {
      color: var(--maincolor);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-left: 5px;
      cursor: pointer
    }
  }
  </style>
  