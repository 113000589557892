<template>
	<div class="d-flex flex-column">
		<TextPlay
			class="mb-3 ml-4 ml-md-0"
			color="var(--text-color)"
			:text="$t('gamification.active_coupons')"
		/>
		<div class="d-flex flex-column mb-4">
			<Coupon
				v-for="(coupon, index) in activateCouponList"
				:enableExclude="enableExclude"
				:key="index"
				:coupon="coupon"
				class="mb-3"
			/>
		</div>
		<TextPlay
			class="mb-3 ml-4 ml-md-0"
			color="var(--text-color)"
			:text="$t('gamification.used_coupons')"
		/>
		<div class="d-flex flex-column">
			<Coupon
				v-for="(coupon, index) in usedCouponList"
				:key="index"
				:coupon="coupon"
				disabled
				class="mb-3"
			/>
		</div>
	</div>
</template>

<script>
import Coupon from "@/components/Coupon.vue";

export default {
	components: {
		Coupon,
	},
	data() {
		return {
			activateCouponList: [],
			usedCouponList: [],
		};
	},
	props: {
		enableExclude: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.activateCouponList = [
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
		];
		this.usedCouponList = [
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
			{ code: "MeuCuponLegal", name: "Estalecas", quantity: 60, money: "60,00" },
		];
	},
};
</script>
