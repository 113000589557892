<template>
	<div class="combos-tab">
		<CombosTable
			@update="$emit('update', $event)"
			:isDeactivated="isDeactivated"
		/>
	</div>
</template>

<script>
import CombosTable from "@/components/CombosTable.vue";

export default {
	components: {
		CombosTable,
	},
	props: {
		isDeactivated: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
