<template>
	<div class="combos-table">
		<div class="combos-table__header">
			<div class="combos-table__header__col-title">
				<TextPlay
					color="var(--fontcolor)"
					weight="semibold"
					:text="$t('combos.title')"
				/>
			</div>
			<div class="combos-table__header__col-title">
				<TextPlay
					color="var(--fontcolor)"
					weight="semibold"
					:text="$t('combos.creation_date')"
				/>
			</div>
			<div class="combos-table__header__col-title">
				<TextPlay
					color="var(--fontcolor)"
					weight="semibold"
					:text="$t('combos.update_date')"
				/>
			</div>
			<div class="combos-table__header__col-title">
				<TextPlay
					color="var(--fontcolor)"
					weight="semibold"
					:text="$t('combos.progress')"
				/>
			</div>
			<div class="combos-table__header__col-title">
				<TextPlay
					color="var(--fontcolor)"
					weight="semibold"
					:text="$t('combos.actions')"
				/>
			</div>
		</div>

		<div class="combos-table__body">
			<ComboTableItem
				:isDeactivated="isDeactivated"
				v-show="combos.length > 0"
				v-for="(combo, index) in combos"
				:key="index"
				:combo="combo"
				@deactivate-combo="handleComboActions"
				@reactivate-combo="handleComboActions"
			/>
			<div v-show="combos.length <= 0">
				<TextPlay
					class="mt-5"
					color="var(--fontcolor)"
					:text="isDeactivated ? $t('combos.no_deactivated_combos') : $t('combos.no_combos')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import TextPlay from "@/components/common/TextPlay.vue";
import ComboTableItem from "@/components/ComboTableItem.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		TextPlay,
		ComboTableItem,
	},
	props: {
		isDeactivated: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleComboActions() {
			this.$emit("update");
		},
	},
	computed: {
		...mapGetters({
			activatedCombos: "combos/getCombos",
			deactivatedCombos: "combos/getDeactivatedCombos",
		}),
		combos() {
			return this.isDeactivated ? this.deactivatedCombos : this.activatedCombos;
		},
	},
};
</script>

<style lang="scss" scoped>
.combos-table {
	.text-play {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 95%;
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 7px;

		&__col-title {
			&:nth-child(1) {
				width: 31.4%;
			}
			&:nth-child(2) {
				width: 19.6%;
			}
			&:nth-child(3) {
				width: 19.6%;
			}
			&:nth-child(4) {
				width: 24.4%;
			}
			&:nth-child(5) {
				width: 5%;
			}
		}
	}
}
</style>
